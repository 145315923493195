import $ from "jquery";
import {
    TweenMax,
    TimelineLite
} from 'gsap'
import ScrollMagic from 'scrollmagic';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
class ProgressiveImageLoading {
    run() {
        this.lqipImages();
        // this.fixFirstImages();
    }

    lqipImages() {

        var controller = new ScrollMagic.Controller();
        $('[data-src]').each(function(){
            var self = $(this);
            var scene = new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 1,
            })
            .setClassToggle(this, 'lazyCustom')
            // .addIndicators()
            .reverse(false)
            .addTo(controller);
        });
        $('[data-background]').each(function(){
            var self = $(this);
            var scene = new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 1,
            })
            .setClassToggle(this, 'lazyCustom')
            // .addIndicators()
            .reverse(false)
            .addTo(controller);
        });

        $(window).on('scroll', function(){
            $('[data-src]').each(function(){
                if($(this).hasClass('lazyCustom')){
                    var imgFullSize = $(this).data('src');
                    $(this).attr('src', imgFullSize);
                    $(this).removeClass('lazyCustom');
                }
            });
            $('[data-background]').each(function(){
                if($(this).hasClass('lazyCustom')){
                    var bgFullSize = $(this).data('background');
                    $(this).css('background-image', 'url(' + bgFullSize + ')');
                    $(this).removeClass('lazyCustom');
                }
            });
        });
    }
}

const progressiveImageloading = new ProgressiveImageLoading();
export default progressiveImageloading;
