import {
    TweenMax,
    TimelineLite
} from 'gsap'
import ScrollMagic from 'scrollmagic';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
// import Scrollbar from 'smooth-scrollbar';


class Animation{
    run(){
        this.loginForm();
        this.animationClasses();
        this.animationParallax();
        this.macroAreaPage();
        this.articlePage();
        this.landingPage();
        // this.listPage();
    }

    animationClasses(){
        // init controller
        const controller = new ScrollMagic.Controller();

        // loop through all elements
        $('.animated').each(function() {
            var element = $(this);
            var dataAnimation = $(this).data('animation');
            new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 1,
            })
            .setClassToggle(this, dataAnimation)
            .reverse(false)
            .addTo(controller);
        });
    }

    animationParallax(){
        const controller = new ScrollMagic.Controller();

        $(document).ready(function(){
            $('#newFullSlider [data-src]').each(function(){
                var imgFullSize = $(this).data('src');
                $(this).attr('src', imgFullSize);
            })
        });

        // HERO Slider
        const newFullSlider = $('#newFullSlider');
        if(newFullSlider.length > 0){
            var fullSlider = new TimelineLite();
            var heroParallax = new TimelineLite();

            // var enterHero = new TimelineLite();
            //
            // enterHero.staggerFromTo('#newFullSlider .text-container p, #newFullSlider .text-container img', 1, { opacity:0, x:20},{ opacity:1, x:0}, .25);

            // fullSlider.fromTo('#newFullSlider #slidesFullscreen img', 1, { scale: 1.2, y: -100, },{ scale: 1.2, y: 100, });

            // heroParallax.fromTo('#full-slider .text-container', .5, { y:0, opacity: 1, },{ y:20, opacity: 0, })
            // .staggerTo('#slider-controls li', 1, { x: 100, }, 0.15)
            // .to('.thumbnails-tool [class*="col"]', 1, { y:100, })
            // .to('.thumbnails-tool', 1, { opacity:0, });

            // var sceneFullSlider = new ScrollMagic.Scene({
            //     duration: '100%',
            //     triggerHook: 'onEnter',
            // })
            // .addIndicators()
            // .setTween(fullSlider)
            // .addTo(controller);

            // var sceneHero = new ScrollMagic.Scene({
            //     // triggerElement: '.thumbnails-tool',
            //     duration: '100%',
            //     triggerHook: .6,
            // })
            // .setTween(heroParallax)
            // .addTo(controller);
        }

        // BOOKING Form
        const bookingForm = $('.container-booking-form');
        if(bookingForm.length > 0){
            const bookingOption = new TimelineLite();

            bookingOption.fromTo('.container-booking-form', 1, { y:100, },{ y:0, },'-= 1')
            .fromTo('.frase-intro p', 1, { opacity: 0, y: 50, lineHeight: '2'},{ opacity: 1, y: 0, lineHeight: '1.2' }, '-= 1');

            var sceneBooking = new ScrollMagic.Scene({
                triggerElement: '.container-booking-form',
                duration: "100%",
                triggerHook: 1,
            })
            // .addIndicators()
            .setTween(bookingOption)
            .addTo(controller);
        }

        // MAIN EVENT Section
        const mainEvent = $('.main-event');
        if(mainEvent.length > 0){
            var animationEvent = new TimelineLite();

            animationEvent.fromTo('.container-img-event', 2, { y:-25, opacity: 0, },{ y:0, opacity: 1, })
            .from('.event-box', 2, { y: 100, },'-= 1');

            var sceneMainEvent = new ScrollMagic.Scene({
                triggerElement: '.main-event',
                duration: "120%",
                triggerHook: 1,
            })
            .setTween(animationEvent)
            .addTo(controller);
        }

        // AUTOSWIPE Gallery
        const galleryCarousel = $('#gallery-carousel');
        if(galleryCarousel.length > 0){
            var galleryAutoscroll = new TimelineLite();

            galleryAutoscroll.fromTo('.gallery-autoscroll', 2, { x:-200, },{ x:0, },'-= 1');

            var sceneGallery = new ScrollMagic.Scene({
                triggerElement: '#gallery-carousel',
                duration: "120%",
                triggerHook: 1,
            })
            .setTween(galleryAutoscroll)
            .addTo(controller);
        }

        // MEDIA GALLERY Section
        const galleryMedia = $('.media-gallery');
        if(galleryMedia.length > 0){

            var mediaGallery = new TimelineLite();

            mediaGallery.from('.media-gallery', 1, { backgroundPosition: '50% 100%' }, '-=1')
            .from('.media-gallery .container-text-gallery', 1, { opacity: 0, y: -100, }, '-=1');

            var sceneMediaGallery = new ScrollMagic.Scene({
                triggerElement: '.media-gallery',
                duration: '100%',
                triggerHook: .9,
            })
            .setTween(mediaGallery)
            .addTo(controller);
        }

        // MORE EVENTS Section
        const relatedEvents = $('.more-events');
        if(relatedEvents.length > 0){

            var moreEvents = new TimelineLite();

            moreEvents.fromTo('.more-events .official-portal', 1, { y:-100, opacity: 0, },{ y:0, opacity: 1, },'-= 1')
            .from('#carousel-events .item:nth-child(even)', 1, { y:-50, }, '-= 1')
            .from('#carousel-events .item:nth-child(odd)', 1, { y:200, }, '-= 1');

            var sceneMoreEvents = new ScrollMagic.Scene({
                triggerElement: '.more-events',
                duration: "120%",
                triggerHook: 1,
            })
            .setTween(moreEvents)
            .addTo(controller);
        }

        // TOPIC Section
        const topicArea = $('.topic-point-area');
        if(topicArea.length > 0){
            topicArea.each(function(){
                var topic = $(this);
                var topicDescription = $(this).find('.point-description');
                var topicTitle = $(this).find('h3');
                var topicParagraph = $(this).find('.point-description p');

                const topicPointArea = new TimelineLite();

                topicPointArea.staggerFrom(topic, 1, {
                    ease: Power1.easeOut,
                    y: 50,
                    opacity: 0,
                }, '-= 1')
                .from(topicTitle, 1, {
                    ease: Power1.easeOut,
                    y: -50,
                    // letterSpacing: '.15em',
                }, '-= 1')
                .from(topicParagraph, 1,{
                    ease: Power1.easeOut,
                    y: -50,
                }, '-= 1')
                .from(topicDescription , 3,{
                    ease: Power1.easeOut,
                    // backgroundColor: 'transparent',
                }, '-= .25');

                var pointMacroarea = new ScrollMagic.Scene({
                    triggerElement: this,
                    duration: '60%',
                    triggerHook: .8,
                })
                .setTween(topicPointArea)
                .addTo(controller);
            });
        }
    }

    macroAreaPage(){
        const controller = new ScrollMagic.Controller();
        const macroArea = new TimelineLite();
        const macroAreaParallax = new TimelineLite();

        // Play automatic after Load
        macroArea.from('.text-left .title', 1, {
            ease: Power2.easeOut,
            opacity: 0,
            letterSpacing: '.2em',
        }, 1)
        .from('.text-left .description', 1.5, {
            ease: Power2.easeOut,
            opacity: 0,
            y: 40,
        }, 1)
        .from('.text-left .caption', 2, {
            ease: Power2.easeOut,
            opacity: 0,
            y: -40,
        }, 1)
        .from('#hero', 1.5, {
            ease: Power2.easeOut,
            minHeight: '100vh',
        }, 1);
        // .from('.container-booking-form', 1, {
        //     y: 300,
        // }, '-= 1');

        // Controlled by ScrollMagic
        macroAreaParallax.to('#hero', 3, {
            maxHeight: 300,
        })



        // var sceneMacroArea = new ScrollMagic.Scene({
        //     // triggerElement: '.media-gallery',
        //     duration: 500,
        //     triggerHook: 0,
        // })
        // .setTween(macroAreaParallax)
        // .addTo(controller);

        // MACRO AREA DESCRIPTION Section
        const areaDescription = $('#macroarea-description');
        if(areaDescription.length > 0){
            var macroAreaDescription = new TimelineLite();

            macroAreaDescription.staggerFrom('.macro-description .caption, .macro-description .title, .macro-description .description', 2, { opacity: 0, y:-20 }, .25).fromTo('#macroarea-description .frase-intro p', 2, { opacity: 0, lineHeight: '1.5', y: 80 },{ opacity: 1, lineHeight: '1', y: 0 }, '-= 1');

            var sceneMacroAreaDescription = new ScrollMagic.Scene({
                triggerElement: '#macroarea-description',
                duration: 300,
                triggerHook: .6,
            })
            .setTween(macroAreaDescription)
            .reverse(false)
            .addTo(controller);
        }
    }

    articlePage(){
        const controller = new ScrollMagic.Controller();
        const macroArea = new TimelineLite();
        const macroAreaParallax = new TimelineLite();

        // Play automatic after Load
        macroArea.fromTo('.article-description .title', 1, {
            ease: Power2.easeOut,
            opacity: 0,
            letterSpacing: '.2em',
        },{
            ease: Power2.easeOut,
            opacity: 1,
            letterSpacing: '0',
        }, 1)
        .from('.article-description .caption', 2, {
            ease: Power2.easeOut,
            opacity: 0,
            y: -40,
        }, 1)
        .from('#article-hero', 1.5, {
            ease: Power2.easeOut,
            minHeight: '100vh',
        }, 1);

        // Controlled by ScrollMagic
        macroAreaParallax.to('#article-hero', 3, {
            maxHeight: 300,
        })
    }

    landingPage(){
        const controller = new ScrollMagic.Controller();
        const macroArea = new TimelineLite();
        const macroAreaParallax = new TimelineLite();

        // Play automatic after Load
        macroArea.fromTo('.hero-description .title', 1, {
            ease: Power2.easeOut,
            opacity: 0,
            letterSpacing: '.2em',
        },{
            ease: Power2.easeOut,
            opacity: 1,
            letterSpacing: '0',
        }, 1)
        .from('.hero-description .caption', 2, {
            ease: Power2.easeOut,
            opacity: 0,
            y: -40,
        }, 1)
        .from('#landing-hero', 1.5, {
            ease: Power1.easeOut,
            minHeight: '100vh',
        }, 1);

        // Controlled by ScrollMagic
        macroAreaParallax.to('#landing-hero', 3, {
            maxHeight: 600,
        })
    }

    listPage(){
        // MACRO AREA DESCRIPTION Section
        const listPage = $('.page-list');
        if(listPage.length > 0){
            const controller = new ScrollMagic.Controller();
            const articleTile = new TimelineLite();

            articleTile.fromTo('article.item', 1, {
                ease: Power2.easeOut,
                opacity: 0,
            },{
                ease: Power2.easeOut,
                opacity: 1,
            }, 1);

            $('article.item').each(function(){
                var self = $(this);
                var scene = new ScrollMagic.Scene({
                    triggerElement: this,
                    triggerHook: .5,
                })
                .addIndicators()
                .setTween(articleTile)
                .reverse(false)
                .addTo(controller);
            });

        }

    }

    loginForm(){
        const pageLogin = $('.page-login');
        if(pageLogin.length > 0){
            const controller = new ScrollMagic.Controller();

            const loginForm = new TimelineLite();

            loginForm.from('.box-login', 1.2, {
                ease: Power2.easeOut,
                x:'100%',
            }, 1).staggerFromTo('.box-login p', .7, {
                ease: Power2.easeOut,
                y: -30,
                opacity: 0,
            },{
                ease: Power2.easeOut,
                y: 0,
                opacity: 1,
            }, .15)
            .fromTo('.box-login .form-login', .7, {
                ease: Power2.easeOut,
                opacity: 0,
            },{
                ease: Power2.easeOut,
                opacity: 1,
            }, '-= 1.5')
            .staggerFromTo('.claim img, .claim h3, .claim p', .7, {
                ease: Power2.easeOut,
                y: -20,
            },{
                ease: Power2.easeOut,
                y: 0,
            }, .25)
            .fromTo('.claim', .7, {
                ease: Power2.easeOut,
                opacity: 0,
            },{
                ease: Power2.easeOut,
                opacity: 1,
            }, '-= .7');
        }
    }

}
const animation = new Animation();
export default animation;
