import $ from 'jquery';

import SmoothScroll from './modules/smoothscroll';
import Animation from './modules/animation';
import Menu from './modules/menu';
import Accessibility from './modules/accessibility';
import HeroSlider from './modules/hero-slider';
import Carousel from './modules/carousel';
import ProgressiveImageLoading from './modules/progressiveimgs';
import Gallery from './modules/gallery';
import Video from './modules/video';
import Filters from './modules/filters';
import LottieAnimate from './modules/lottie';
// import Credits from './modules/credits';

// import preloader from './modules/preloader';

window.addEventListener('DOMContentLoaded', function(){
    $('html').removeClass('no-js').addClass('js');
    Animation.run();
    Menu.run();
    Accessibility.run();
    HeroSlider.run();
    Carousel.run();
    ProgressiveImageLoading.run();
    Gallery.run();
    Video.run();
    Filters.run();
    LottieAnimate.run();
    SmoothScroll.run();
    // Credits.run();

    // $('.button-book-room').bind('click', function(e) {
    //     e.preventDefault();
    //     var startDate = $('.arrival-date input').val();
    //     var endDate = $('.departure-date input').val();
    //     var occupancy = $('.guests-number input').val();

    //     if(startDate == "" || endDate == "") {
    //         var startDateObject = new Date();
    //         var endDateObject = new Date(startDateObject);
    //         startDate = startDateObject.toLocaleDateString("en-US");
    //         endDate = startDate; //endDateObject.setDate(startDateObject.getDate()+1).toLocaleDateString("en-US");
    //     }

    //     if(isNaN(occupancy) || occupancy<0 || occupancy == "") {
    //         occupancy = 1;
    //     }

    //         dw.accommodationQuickFind(
    //         'termecolli',
    //         {
    //          dateFrom:new Date(startDate),
    //          dateTo:new Date(endDate),
    //          occupancy1:{
    //          units:1,
    //          adults:occupancy
    //          }
    //          },
    //          function() {
    //          window.location.href = "https://vam.noooserver.com/booking-new/"
    //          })
    // });
});

window.addEventListener('load',function() {
    // preloader.run();
    // setTimeout(function(){
    //     // $('html').removeClass('overflow-hidden');
    // animation.run();
    // }, 3000);
    // setTimeout(function(){
    //     $('html').removeClass('overflow-hidden');
    // }, 4000);
});
