import $ from 'jquery';
import {tns} from 'tiny-slider/src/tiny-slider';
import "slick-carousel";

class Carousel{
    run(){
        // this.fullWidthCarousel();
        this.mediaCarousel();
        this.eventsCarousel();
        this.articleCarousel();
        this.galleryAutoScroll();
    }

    mediaCarousel(){
        var navContainer = $('.media-carousel').parent().find('.nav-container');

        $('.media-carousel').slick({
            slidesToShow: 1,
            infinite: true,
            arrows: true,
            appendArrows: navContainer,
            prevArrow: $('.media-carousel').parent().find('.nav-container .prev'),
            nextArrow: $('.media-carousel').parent().find('.nav-container .next'),
            // autoplay: true,
            centerMode: true,
            // mobileFirst: true,
            // dots: true,
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                        centerMode: false,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
        // .on('beforeChange afterChange', function(event, slick, currentSlide, nextSlide, prevSlide){
        //     if($('.slick-current video').length > 0){
        //         $('.slick-slide video')[0].pause();
        //         $('.slick-active video')[0].play();
        //     } else {
        //         $('.slick-slide video')[0].pause();
        //     }
        // });
    }

    eventsCarousel(){
        $('#carousel-events').each(function(){
            const $this = $(this);
            const carousel = tns({
                container: $this[0],
                items:1,
                mouseDrag:true,
                nav:false,
                loop:false,
                lazyload: true,
                controls:false,
                responsive: {
                    800:{
                        items:3,
                        controls: true,
                        controlsText: ['',''],
                        controlsPosition: 'bottom'
                    },
                    760:{
                        items:2.2
                    },
                    500: {
                        items: 2
                    },
                    320: {
                        items: 1,
                    },
                }
            });
        });
    }

    articleCarousel(){
        $('.gallery').each(function(){
            const $this = $(this);
            const carousel = tns({
                container: $this[0],
                items:1,
                mouseDrag:true,
                nav:false,
                loop:false,
                controls:false,
                disable: false,
                responsive: {
                    500: {
                        items:3,
                    },
                    769: {
                        disable: true,
                        items:3,
                    },
                }
            });
        });
    }

    galleryAutoScroll(){
        var galleryImgWidth = 0;
        var galleryImg = $('#gallery-carousel .gallery-autoscroll img');

        galleryImg.each(function () {
            galleryImgWidth = galleryImgWidth + $(this).outerWidth();
        });

        if ($(window).outerWidth() > galleryImgWidth) {
            galleryImg.each(function() {
                $(this).clone().appendTo( ".gallery-autoscroll" );
            });
        }
    }
}
const carousel = new Carousel();
export default carousel;
