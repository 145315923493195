import $ from 'jquery';
import "lightgallery";
import "lg-zoom";
import "lg-pager";
import "lg-thumbnail";
import "lg-share";
import "lg-video";
import "lg-fullscreen";
import "lg-pager";

class Gallery{
    run(){
        this.fotoGallery();
        this.videoGallery();
    }
    fotoGallery(){
        $('#lightgallery').lightGallery({
            thumbnail:false,
            selector: '.item',
            mode: 'lg-fade',
            subHtmlSelectorRelative: true,
            // nextHtml: '<button class="btn-arrow right lg-next"></button>',
            // prevHtml: '<button class="btn-arrow left lg-prev"></button>',

        });
        $('.gallery-autoscroll img').on('click',function(e){
            var target = $(e.target).data('src');
            $('#lightgallery li[data-src="' + target + '"' ).click();
        });
        
        $('.lightGalleryPage').each(function(index, el) {
            $(el).lightGallery({
            thumbnail:false,
            selector: '.item',
            mode: 'lg-fade',
            subHtmlSelectorRelative: true,
            // nextHtml: '<button class="btn-arrow right lg-next"></button>',
            // prevHtml: '<button class="btn-arrow left lg-prev"></button>',

        })});;
        
        $('.gallery-page img').on('click',function(e){
            var target = $(e.target).data('src-big');
            console.log(target);
            $('.lightGalleryPage li[data-src="' + target + '"' ).click();
        });

    }

    videoGallery(){
        $('#video-gallery').lightGallery({
            videojs: true
        });

    }
}
const gallery = new Gallery();
export default gallery;
