import $ from 'jquery';
import "slick-carousel";

class HeroSlider{
    run(){
        this.newFullScreenSlider();
    }
    
    newFullScreenSlider(){
        var autoplayN = localStorage.getItem('autoplay');
        var autoplay = false;
        //if (autoplayN == null) {
            localStorage.setItem('autoplay', 1);
            autoplay = true;
        //}
        // const thumbnails = $('#slidesThumbnails .slick-slide')
        $('#slidesFullscreen').not('.slick-initialized').slick({
            dots: false,
            arrows: false,
            infinite: true,
            autoplay: autoplay,
            speed: 1000,
            autoplaySpeed: 30000,
            fade: true,
            cssEase: 'linear'
        })
        .on('beforeChange afterChange', function(event, slick, currentSlide, nextSlide, prevSlide){
            if($('.slick-current video').length > 0){
                $('.slidesFullscreen').addClass('video-playing');
                $('.slick-slide video')[0].pause();
                $('.slick-active video')[0].play();
            } else {
                $('.slick-slide video')[0].pause();
                $('.slidesFullscreen').removeClass('video-playing');
            }
        });


        $('#slidesCaptions').not('.slick-initialized').slick({
            dots: false,
            arrows: false,
            infinite: false,
            autoplay: autoplay,
            speed: 1000,
            autoplaySpeed: 30000,
            slidesToShow: 1,
            fade: true,
            cssEase: 'linear',
            // variableWidth: true,
        });

        $('#slidesThumbnails').not('.slick-initialized').slick({
            autoplay: false,
            slidesToScroll: 1,
            asNavFor: '#slidesFullscreen, #slidesCaptions',
            dots: true,
            dotsClass: 'sliderDots',
            appendDots: '#controlDots',
            arrows: true,
            appendArrows: '#controlArrows',
            centerMode: false,
            focusOnSelect: true,
            variableWidth: true,
            infinite: true,
            draggable: true,
        });
    }
}
const heroslider = new HeroSlider();
export default heroslider;
