import $ from "jquery";

class Filters {
    run() {
        this.filtersToggler();
        this.filters();
        //this.loadMore();

        var that = this;
        /*$('#loadmore').on('click', function(){
            that.loadMore()
        });*/

        $('#filter-apply').on('click', function(){
            //that.filterResult()
            $('#filters').removeClass('active');
            $('body').removeClass('overflow-hidden');
        });

        this.filterTag = []
        $("[data-filter-tag]").click(function(){
            var id = $(this).data("filter-tag");
            $(this).toggleClass("checked");
            if(that.filterTag.includes(id))
            {
                that.filterTag = that.filterTag.filter(item => item !== id);
            }else
            {
                that.filterTag = that.filterTag.concat(id);
            }
            that.filterByTag();
        });

    }

    filterByTag()
    {
        if(this.filterTag.length === 0)
            $("article.item").removeClass("d-none");
        else
        {
            $("article.item").addClass("d-none");
            this.filterTag.forEach((tag) => {
                $("[data-tag='"+tag+"']").parent().parent().parent().parent().removeClass("d-none");
            })
        }
    }

    filtersToggler(){
        $('#filter-show').on('click', function(){
            $('#filters').toggleClass('active');
            $('body').toggleClass('overflow-hidden');
            $('#filters .filters-actions').addClass('active');
        });
        $('#triggerFilterOpen').on('click', function(){
            $('#filters').toggleClass('active');
            $('body').toggleClass('overflow-hidden');
            $('#filters .filters-actions').addClass('active');
        });
    }

    filterResult(){
        var slugs = [];
        $('.active-filter[data-slug]').each(function(){
            slugs.push($(this).data('slug'));
        });

        // console.log(slugs);
        var count = 0;

        $('article.item').each(function(){
            var terms = $(this).data('terms');
            var element = $(this);

            element.removeClass('selected');
            element.addClass('d-none');

            if(slugs.length == 0){
                element.addClass('selected');
                count++;
            }

            slugs.forEach(function(item){
                if(terms.includes(item)){
                    element.addClass('selected');
                    count++;
                }
            })
        });

        if(count == 0){
            $('#loadmore').addClass('d-none');
        }

        $('#results-length').html(count);
        this.loadMore();
    }

    filters(){
        var that = this;
        var filterCount = 0;

        // CHECK If there are active inputs
        $('#filters input').on('click', function(){
            var elem = $("#filters input");
            var count = elem.filter(function() {
                return !$(this).val();
            }).length;
            if (count == elem.length) {
                //$('#search-header .filter-btn-close').removeClass('visible');
            } else {
                $('#filters .filters-actions').addClass('active');
            }

            var data_slug = $(this).data('slug');
            var data_label = $(this).data('label');

            if($([this]).prop("checked") == true){
                var button = $('<button data-slug="'+data_slug+'" class="m-1 active-filter">'+data_label+' <i class="icon-close"></i></button>');
                filterCount++;

                button.on('click', function(){
                    console.log(data_slug);
                    $(this).remove();
                    $('#filters').find($('[data-slug="'+data_slug+'"]')).prop('checked', false);
                    that.filterResult();
                });
                button.appendTo('.result-active-filters');
            } else {
                $('.result-active-filters').find($('[data-slug="'+data_slug+'"]')).remove();

                filterCount--;
                if (filterCount < 0){
                    filterCount = 0;
                }
            }
            $('.filter-count strong').html(filterCount);

            if(filterCount <= 0){
                $('.filter-count').removeClass('active');
            } else {
                $('.filter-count').addClass('active');
            }
        });

        $('.result-active-filters .active-filter').on('click', function(){
            // alert($(this));
            $(this).remove();
            $('#filters input[data-slug="'+data_slug+'"]').prop("checked", false);
        });

        //
        // CLEAR FILTERS
        //
        $('#filters .btn-delete').on('click', function(){
            filterCount = 0;
            $('#filters input').prop("checked", false);
            $('.result-active-filters button').remove();
            $('#filters').removeClass('active');
            $('body').removeClass('overflow-hidden');
            $('#filters .filters-actions').removeClass('active');
            $('.filter-count strong').html(filterCount);
            $('.filter-count').removeClass('active');
            that.filterResult();
        });
    }

    loadMore(){
        $('#loadmore').addClass('d-none');
        var hidden = $("article.item.d-none.selected");
        var i = 0;
        hidden.each(function(){
            if(i < 12) {
                $(this).removeClass("d-none");
                // var src = $(this).find(".main-img").attr("data-src");
                // $(this).find(".main-img").attr("src", src);
            }
            i++;
        });
        if(i > 12){
            $('#loadmore').removeClass('d-none');
        }
    }
}

const filters = new Filters();
export default filters;
