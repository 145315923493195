import $ from "jquery";
import lottie from "lottie-web";

class LottieAnimate {
    run() {
        this.lottieAnimation();
    }

    lottieAnimation(){
        const lottieAnim = $('#lottie');
        if(lottieAnim.length > 0){
            var lottieJSON = $('#lottie').data('lottie');

            lottie.loadAnimation({
                container: $('.lottie')[0],
                path: lottieJSON,
                renderer: 'canvas',
                loop: true,
                autoplay: true,
                name: "water-lottie",
            })
        }
    }
}

const lottieAnimate = new LottieAnimate();
export default lottieAnimate;
