import $ from 'jquery';

// Insert your costum JS
class Menu{
    run(){
        this.menuInit();
        this.showMenu();
        this.subMenuToggler();
        this.searchForm();
        this.floatingToggler();
        this.btnBackTop();
        this.languageSwitcher();
    }

    menuInit(){
        const absoulteLinkContainer = $('#interactive-container');

        $('#menu-toggler, #floatingMenuToggler').on('click', function(){
            const heightV = $('#children-menu .active').outerHeight();
            absoulteLinkContainer.height(heightV - 10.4);
        });
    }

    showMenu(){
        var defaultMenu = $('#children-menu .default');
        var childrenContainer = $('#children-menu .children');
        var childrenMenu = $('#children-menu .children [data-menu]');
        var parentMenu = $('#parent-menu');
        var parentItem = $('#parent-menu li a');

        //TOGGLE MENU
        $('#menu-toggler, #floatingMenuToggler').click(function(){

            $('#main-navigation').addClass('active');
            $('body').addClass('menu-active');

            $('#main-navigation').prepend('<div id="overlay-filter"></div>');

            $('#overlay-filter').on('click', function(){
                $('#main-navigation').removeClass('active');
                $('body').removeClass('menu-active').removeClass('overlay');
                var timeout = setTimeout(function(){
                    $('#overlay-filter').remove();
                }, 1800)

                parentItem.removeClass('current');
                defaultMenu.removeClass('not-active').addClass('active');
                childrenContainer.removeClass('active').addClass('not-active');
                childrenMenu.addClass('not-active');
                parentMenu.removeClass('disabled');

                $('.btn-booking').removeClass('mobile-disable');
                $('.topic-image').removeClass('mobile-active');

                if($(window).scrollTop() > 100){
                    $("#floatingMenuToggler, #external-links").addClass("active");
                }
            });

            if($(window).scrollTop() > 100){
                $("#floatingMenuToggler, #external-links").removeClass("active");
            }
        });

        $('.close-button-menu').click(function(){
            $('#main-navigation').removeClass('active');
            $('body').removeClass('menu-active').removeClass('overlay');
            var timeout = setTimeout(function(){
                $('#overlay-filter').remove();
            }, 1800)

            parentItem.removeClass('current');
            defaultMenu.removeClass('not-active').addClass('active');
            childrenContainer.removeClass('active').addClass('not-active');
            childrenMenu.addClass('not-active');
            parentMenu.removeClass('disabled');

            $('.btn-booking').removeClass('mobile-disable');
            $('.topic-image').removeClass('mobile-active');

            if($(window).scrollTop() > 100){
                $("#floatingMenuToggler, #external-links").addClass("active");
            }
        });
    }

    subMenuToggler(){
        var defaultMenu = $('#children-menu .default');
        var childrenContainer = $('#children-menu .children');
        var childrenMenu = $('#children-menu .children [data-menu]');
        var parentMenu = $('#parent-menu');
        var parentItem = $('#parent-menu li a');
        var childrenItem = $('#children-menu .children li');
        var backLink = $('#children-menu .children .back-link');


        childrenMenu.addClass('not-active');

        parentItem.on('click',function(){
            var currentItemMenu = $('#children-menu .children [data-menu="'+$(this).data('menu')+'"]');

            if(defaultMenu.hasClass('not-active')){
                // console.log('1');

                parentItem.removeClass('current');

                defaultMenu.removeClass('not-active').addClass('active');
                // childrenContainer.removeClass('active').addClass('not-active');
                childrenMenu.addClass('not-active');

                currentItemMenu.addClass('not-active');
                parentMenu.toggleClass('disabled');

                $('.btn-booking').removeClass('mobile-disable');
                $('.topic-image').removeClass('mobile-active');

                $(this).addClass('current');

                defaultMenu.addClass('not-active').removeClass('active');
                childrenMenu.addClass('not-active');

                currentItemMenu.removeClass('not-active');
                parentMenu.toggleClass('disabled');

                $('.btn-booking').addClass('mobile-disable');
                $('.topic-image').addClass('mobile-active');

            } else {
                // console.log('2');
                parentItem.removeClass('current');
                $(this).addClass('current');

                defaultMenu.addClass('not-active').removeClass('active');
                childrenContainer.removeClass('not-active').addClass('active');
                childrenMenu.addClass('not-active');

                currentItemMenu.removeClass('not-active');
                parentMenu.toggleClass('disabled');

                $('.btn-booking').addClass('mobile-disable');
                $('.topic-image').addClass('mobile-active');
            }
        });

        backLink.on('click',function(){
            var currentItemMenu = $('#children-menu .children [data-menu="'+$(this).data('menu')+'"]');

            parentItem.removeClass('current');

            defaultMenu.addClass('active').removeClass('not-active');
            childrenContainer.removeClass('active').addClass('not-active');
            childrenMenu.addClass('not-active');

            currentItemMenu.removeClass('not-active');
            parentMenu.removeClass('disabled');

            $('.btn-booking').removeClass('mobile-disable');
            $('.topic-image').removeClass('mobile-active');

        });
    }

    searchForm(){
        $('#search-toggler').on('click',function(){
            $(this).toggleClass('active');
            $('#main-navigation').toggleClass('search-on search-off');
        });
    }

    floatingToggler(){
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();

            //>=, not <=
            if (scroll > 100) {
                $("#floatingMenuToggler, #external-links").addClass("active");
            }
            else{
                $("#floatingMenuToggler, #external-links").removeClass("active");
            }
        });
    }

    btnBackTop(){
        $('#btnBackTop').on('click',function(e){
            e.preventDefault();
            $("html, body").animate({ scrollTop: 0 }, 1500);
            return false;
        });

        $(window).scroll(function() {
            var scroll = $(window).scrollTop();

            //>=, not <=
            if (scroll > 100) {
                $("#btnBackTop").addClass("active");
            }
            else{
                $("#btnBackTop").removeClass("active");
            }
        });
    }

    languageSwitcher() {
        $('.wpml-ls-item-toggle').each(function(){
            var lang = $(this).text().slice(0,2);
            $(this).text(lang);
        });
    }
}
const menu = new Menu();
export default menu;
