// import $ from "jquery";

class SmoothScroll {
	run() {
		this.scrollBehavior();
		// this.smoothMouseWheel();
	}

	scrollBehavior() {
		$// Select all links with hashes
		$('a[href*="#"]')
		  // Remove links that don't actually link to anything
		  .not('[href="#"]')
		  .not('[href="#0"]')
		  .on('click', function(event) {
		    // On-page links
		    if (
		      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
		      &&
		      location.hostname == this.hostname
		    ) {
		      // Figure out element to scroll to
		      var target = $(this.hash);
		      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
		      // Does a scroll target exist?
		      if (target.length) {
		        // Only prevent default if animation is actually gonna happen
		        event.preventDefault();
		        $('html, body').animate({
		          scrollTop: target.offset().top - 50
		        }, 1000);
		      }
		    }
		  });
	}

	// smoothMouseWheel(){
	//
	// 	var $window = $(window);		//Window object
	//
	// 	var scrollTime = 1.2;			//Scroll time
	// 	var scrollDistance = 120;		//Distance. Use smaller value for shorter scroll and greater value for longer scroll
	//
	// 	$window.on("mousewheel DOMMouseScroll", function(event){
	//
	// 		event.preventDefault();
	//
	// 		var delta = event.originalEvent.wheelDelta/120 || -event.originalEvent.detail/3;
	// 		var scrollTop = $window.scrollTop();
	// 		var finalScroll = scrollTop - parseInt(delta*scrollDistance);
	//
	// 		TweenMax.to($window, scrollTime, {
	// 			scrollTo : { y: finalScroll, autoKill:true },
	// 				ease: Power1.easeOut,	//For more easing functions see https://api.greensock.com/js/com/greensock/easing/package-detail.html
	// 				autoKill: true,
	// 				overwrite: 5
	// 			});
	//
	// 	});
	//
	// };

}

const smoothScroll = new SmoothScroll();
export default smoothScroll;
